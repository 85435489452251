<template>
  <div>
    <v-layout
      style="margin: 10px"
      v-if="data.data"
      row
      wrap
      v-show="!loading_detail"
    >
      <v-flex sm4 xs12>
        <div
          v-if="image_attribute"
          :style="
            'background-image: url(' +
            data.data[image_attribute] +
            ');background-size:cover;width:100%;height:300px;background-position:center;'
          "
          width="100%%"
          :alt="data.data[header_attribute]"
        />
      </v-flex>
      <v-flex xs12 sm8 order-sm-first>
        <h1>{{ data.data[header_attribute] }}</h1>
        <v-list
          v-if="detail_view_content.length !== 0"
          style="background: transparent"
        >
          <div v-for="content in detail_view_content" :key="content.name">
            <v-list-item v-if="data.data[content.value]">
              <v-list-item-content three-line>
                <v-list-item-title>{{ content.name }}</v-list-item-title>
                <v-list-item-subtitle
                  v-html="
                    content.formatter === undefined
                      ? data.data[content.value]
                      : content.formatter(data.data[content.value])
                  "
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-flex>
    </v-layout>
    <div
      style="width: 100%; text-align: center; padding: 40px"
      v-if="loading_detail"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { storage } from "../../schmucklicloud";
export default {
  props: {
    item_id: {
      type: Number,
      required: true,
    },
    iden: {
      type: String,
    },
    container_name: {
      // The container of the schmuckliCloud service, which should display the data.
      type: String,
      required: true,
    },
    header_attribute: {
      // The main attribute, which should be on top
      type: String,
      default: "name",
    },
    detail_view_content: {
      type: Array,
      required: false,
    },
    image_attribute: {
      type: String,
      required: false,
    },
    refresh_counter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      loading_detail: true,
    };
  },
  methods: {
    async loadSelected() {
      this.loading_detail = true;
      var response = await storage.getById(this.container_name, this.item_id);
      if (response.isOK) {
        this.data = response.data || {};
      }
      this.loading_detail = false;
    },
  },
  watch: {
    item_id() {
      this.loadSelected();
    },
    refresh_counter() {
      this.loadSelected();
    },
  },
};
</script>

<style>
</style>