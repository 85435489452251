import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n'
import * as VueGoogleMaps from 'vue2-google-maps'
import { GOOGLE_MAPS_KEY } from "./keys";
import VueApexCharts from 'vue-apexcharts';
import moment from "moment-timezone";
import elements from "./elements";

Vue.component('apexchart', VueApexCharts);

import Router from "./router";

Vue.use(VueI18n);

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAPS_KEY
  },
  installComponents: true
});

//Localization
import de from "./assets/i18n/de";
import en from "./assets/i18n/en";

const i18n = new VueI18n({
  locale: getLanguage(), // set locale
  messages: {
    de: de,
    en: en
  } // set locale messages
});

export function getLanguage(){
  return (window.localStorage.getItem("lang") || navigator.language || navigator.userLanguage).split("-")[0];
}

Vue.prototype.moment = moment;

elements.init(Vue);

new Vue({
  i18n,
  vuetify,
  router: Router,
  render: h => h(App)
}).$mount('#app')
