<template>
  <v-dialog max-width="900px" v-model="open">
    <v-form @submit="editItem">
      <v-card>
        <v-card-title>{{ $t("general.edit") }}</v-card-title>
        <v-card-text>
          <div v-for="element in elements" :key="element.name">
            <v-text-field
              v-if="element.type === 'text'"
              :label="element.label"
              v-model="form[element.name]"
              :placeholder="element.placeholder || ''"
              :aria-required="element.required"
            ></v-text-field>
            <v-select
              v-else-if="element.type === 'select'"
              :items="element.options"
              :label="element.label"
              v-model="form[element.name]"
              :aria-required="element.required"
            ></v-select>
            <div v-else-if="element.type === 'rating'">
              <p style="display: block; font-weight: bold" for="file">
                {{ element.label }}
              </p>
              <v-rating
                hover
                :length="element.max"
                size="30"
                v-model="form[element.name]"
              ></v-rating>
            </div>
            <v-textarea
              v-else-if="element.type === 'textarea'"
              v-model="form[element.name]"
              :label="element.label"
              :placeholder="element.placeholder || ''"
            ></v-textarea>
            <div
              v-else-if="element.type === 'file'"
              style="padding-top: 20px; padding-bottom: 20px"
            >
              <div v-if="form[element.name] === undefined || form[element.name] === ''">
                <p
                  style="display: block; font-weight: bold;"
                  for="file"
                >
                  {{ element.label }}
                </p>
                <v-btn
                  @click="
                    file_dialog = true;
                    file_dialog_name = element.name;
                  "
                  >{{ $t("elements.select_file") }}</v-btn
                >
              </div>
              <div v-else>
                <table style="width: 100%">
                  <tr>
                    <td>{{ $t("elements.selected") }}</td>
                    <td style="text-align: right">
                      <i
                        class="fad fa-times"
                        style="font-size: 20px; cursor: pointer"
                        @click="
                          form[element.name] = '';
                          file_dialog_data = undefined;
                        "
                      ></i>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn type="button" @click="deleteItem" color="error" text
            ><i class="fad fa-trash" style="margin-right: 5px"></i
            >{{ $t("general.delete") }}</v-btn
          >
          <v-btn type="submit" primary text
            ><i class="fad fa-save" style="margin-right: 5px"></i
            >{{ $t("general.edit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog max-width="500px" v-model="file_dialog">
      <v-card>
        <v-card-title>{{ $t("elements.select_file") }}</v-card-title>
        <v-card-text>
          <div v-if="!file_dialog_loading">
            <v-tabs
              v-model="file_dialog_tab"
              background-color="transparent"
              grow
            >
              <v-tab key="upload">
                {{ $t("elements.upload") }}
              </v-tab>
              <v-tab key="url">
                {{ $t("elements.url") }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="file_dialog_tab">
              <v-tab-item key="upload">
                <v-card flat>
                  <v-card-text>
                    <v-file-input
                      :label="$t('elements.select')"
                      @change="uploadFile"
                      v-model="file_dialog_data"
                    ></v-file-input>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="url">
                <v-card flat>
                  <v-card-text>
                    <v-text-field
                      :label="$t('elements.url')"
                      v-model="file_dialog_data"
                      placeholder="https://"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div v-else>
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="selectFile" text>{{ $t("elements.select") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { Notifications } from "../../notification";
import { storage } from "../../schmucklicloud";
import axios from "axios";

export default {
  props: {
    open: {
      type: Boolean,
    },
    id: {
      type: Number,
      required: true,
    },
    container_name: {
      type: String,
      required: true,
    },
    elements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {},

      file_dialog: false,
      file_dialog_tab: undefined,
      file_dialog_data: undefined, // The data like the url to the image.
      file_dialog_name: "", // The attribute name for this.form.
      file_dialog_loading: false,
    };
  },
  methods: {
    async loadData() {
      var response = await storage.getById(this.container_name, this.id);
      if (response.isOK) {
        console.log(response);
        this.form = response.data.data;
      } else {
        Notifications.show("Cannot open the edit dialog for " + this.id);
        this.$emit("closed");
      }
    },
    async editItem(e) {
      e.preventDefault();

      var response = await storage.update(
        this.container_name,
        this.id,
        this.form
      );
      if (response.isOK) {
        Notifications.show("The item has been saved.");
        this.$emit("closed");
      } else {
        Notifications.show(response.message);
      }
    },
    async deleteItem() {
      if (confirm("Do you really want to delete this item")) {
        var response = await storage.delete(this.container_name, this.id);
        if (response.isOK) {
          Notifications.show("The item has been deleted.");
          this.$emit("deleted");
        } else {
          Notifications.show(response.message);
        }
      }
    },
    selectFile() {
      this.form[this.file_dialog_name] = this.file_dialog_data;
      this.file_dialog = false;
      this.file_dialog_loading = false;
    },
    uploadFile() {
      this.file_dialog_loading = true;
      var reader = new FileReader();
      reader.onload = function (e) {
        axios({
          method: "POST",
          url: "https://api.imgur.com/3/image",
          headers: { Authorization: "Client-ID 8b7351949aa283f" },
          data: { image: e.target.result.split(",")[1] },
        }).then(
          function (response) {
            if (response.status === 200) {
              Notifications.show("File has been uploaded.");
              this.file_dialog_data = response.data.data.link;
              this.selectFile();
            } else {
              Notifications.show("There was an error while upload the file.");
              this.file_dialog_data = undefined;
              this.file_dialog_loading = false;
            }
          }.bind(this)
        );
      }.bind(this);
      reader.readAsDataURL(this.file_dialog_data);
    },
  },
  watch: {
    open(isOpen) {
      if (!isOpen) {
        this.$emit("closed");
      }
    },
    id() {
      this.loadData();
    },
  },
};
</script>

<style>
</style>