import EList from "./components/elements/EList";
import EDetailView from "./components/elements/EDetailView";
import EMasterDetailView from "./components/elements/EMasterDetailView";
import EAddDialog from "./components/elements/EAddDialog";
import EEditDialog from "./components/elements/EEditDialog";

var init = function (vue) {
    vue.component("EList", EList);
    vue.component("EDetailView", EDetailView);
    vue.component("EMasterDetailView", EMasterDetailView);
    vue.component("EAddDialog", EAddDialog);
    vue.component("EEditDialog", EEditDialog);
}

export default { init };

