import Vue from 'vue';

const NotificationsReceiver = Vue.observable({
  notification: ""
});

const Notifications = {
  show(notification){
    NotificationsReceiver.notification = notification;
  }
};

export { NotificationsReceiver, Notifications};