import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "fa"
    },
    theme: {
        themes: {
            light: {
                primary: "#FFB024",
                accent: "#FFB024"
            },
            dark: {
                primary: "#FFB024",
                accent: "#FFB024"
            }
        }
    }
});
