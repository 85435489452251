const en = {
    general: {
        app_name: "Tracky",
        entries: "Entries",
        media: "Media",
        other: "Other",
        settings: "Settings",
        location: "Locations",
        general: "General",
        activities: "Activities",
        dark_mode: "Dark mode",
        save: "Save",
        edit: "Edit",
        cancel: "Cancel",
        delete: "Delete",
        new: "New",
        close: "Close",
        search: "Search",
        text: "Text",
        no_date_defined: "No date defined",
        day_of_week: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday"
        },
        month: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December"
        }
    },
    today: {
        notification: {
            entry_saved: "The entry has been saved."
        },
        header: "Daily",
        add: "Add",
        title: "Title",
        text: "Text",
        save: "Save",
        mood: "Mood",
        entry: "Entry",
        edit: "Edit",
        select_date: "Select date",
        total_locations_at_this_point: "Total locations at this point"
    },
    mood_card: {
        notification: {
            mood_saved: "The mood has been saved."
        },
        mood: "Mood"
    },
    location_card: {
        header: "Location",
        open_location_points: "Open location points"
    },
    sessioncard: {
        delete: "Delete",
        device_name: "Device name",
        device_os: "Device OS",
        started: "Started"
    },
    settings: {
        header: "Settings",
        language: "Language",
        change_password: "Change password",
        current_password: "Current password",
        new_password: "New password",
        repeat_new_password: "Repeat new password",
        change: "change",
        profile: "Profile",
        sign_out: "Sign out",
        devices: "Devices",
        devices_description: "You find here all devices where you are signed in.",
        setup_two_factor: "Setup 2FA",
        type_here_the_six_digit_code: "Type here the six digit code:",
        code: "Code",
        disable_two_factor: "Disable 2FA",
        setup: "Setup",
        export_my_data: "Export my data"
    },
    register: {
        email: "Email",
        password: "Password",
        repeat_password: "Repeat password",
        register: "Register",
        or_want_to_login: "or want to login?"
    },
    activate: {
        notification: {
            user_activated: "The user is now activated."
        }
    },
    login: {
        login: "Login",
        email: "Email",
        password: "Password",
        register_now: "Register now",
        forgot_password: "Forgot password?",
        two_factor_authentication: "Two factor authentication",
        verify: "Verify"
    },
    forgot_password: {
        header: "Forgot password?",
        header_description: "Enter below your email of your account. We will send then you a link, where you can reset your password.",
        email: "Email",
        request_password: "Request password",
        or_want_to_login: "Sign in?"
    },
    reset_password: {
        notification: {
            new_password_has_been_set: "The new password has been set.",
            passwords_do_not_match: "The passwords do not match with each other."
        },
        header: "Reset password",
        header_description: "You can set here now a new password for your account. This password will then be valid.",
        new_password: "New password",
        repeat_new_password: "Repeat new password",
        reset: "Reset"
    },

    timeline: {
        header: "Timeline",
        no_data_recorded_for_this_date_yet: "No data recorded for this day yet.",
        install_the_companion: "Install the companion app to upload data automatically.",
        or: "Or",
        try_import_snapplace: "Try to import from SnapPlace",
        you_can_import_this_day_tomorrow: "You can import this day tomorrow.",
        on_the_way: "On the way"
    },

    tracks: {
        notification: {
            track_has_been_imported: "The track has been imported.",
            file_not_supported: "This file is not supported.",
            track_has_been_saved: "The track has been saved.",
            track_has_been_deleted: "The track has been deleted."
        },
        types: {
            driving: "Driving",
            walking: "Walking",
            running: "Running",
            hiking: "Hiking",
            biking: "Biking",
            sailing: "Sailing",
            boating: "Boating",
            skiing: "Skiing",
            snowboarding: "Snowboarding",
            sledding: "Sledding"
        },
        header: "Tracks",
        import: "Import",
        name: "Name",
        elevation: "Elevation",
        meters_above_sea: "Meters above sea",
        bpm: "BPM",
        heartRate: "Heart rate",
        distance: "Distance",
        duration: "Duration",
        date: "Date",
        time: "Time",
        edit: "Edit",
        type: "Type",
        search: "Search for tracks...",
        heartRateAvg: "Average Heart Rate",
        uphill: "Altitude uphill",
        downhill: "Altitude downhill"
    },

    fitness: {
        header: "Fitness",
        import: "Import",
        steps: "Steps",
        resting_heart_rate: "Average heart rate",
        calories: "Calories",
        floors: "Floors",
        import_data: "Import from services",
        distance: "Distance",
        sleep_effective: "Effective sleep",
        sleep_total: "Total sleep",

        no_data_here: "There are no data for this date.",
        stats: "Fitness Stats",

        all_time: "All-time",
        all_time_steps: "All-time steps",
        all_time_distance: "All-time distance",
        all_time_average_steps: "All-time average daily steps",
        all_time_floors: "All-time floors"
    },

    movies: {
        notification: {
            saved_personal_experience: "The personal experience has been saved.",
            personal_experience_deleted: "The personal experience has been deleted."
        },
        do_you_really_want_to_delete: "Do your really want to delete this experience?",
        header: "Movies",
        back: "Back",
        runtime: "Runtime",
        minutes: "Minutes",
        search_for_movies: "Search for movies...",
        have_you_watched_this_movie: "Have you watched this movie?",
        watched_where: "Watched were?",
        watched_where_cinema: "Cinema",
        watched_where_streamed: "Streamed (Netflix, Disney+, ...)",
        watched_where_tv: "TV (Free-TV Premiere, ...)",
        watched_where_both: "Watched in cinema and streamed or saw in TV",
        month: "Month",
        year: "Year",
        rating: "Rating",
        watched_when: "Watched when?",
        personal_experience: "Personal experience",
        library: "Library"
    },

    tvshows: {
        notification: {
            saved_personal_experience: "The personal experience has been saved.",
            personal_experience_deleted: "The personal experience has been deleted."
        },
        do_you_really_want_to_delete: "Do you really want to delete this experience?",
        header: "TV Shows",
        search_for_tvshows: "Search for TV shows...",
        library: "Library",
        seasons: "Seasons",
        back: "Back",
        have_you_watched_this_show: "Have you watched this show?",
        rating: "Rating",
        watched_when: "When did you start with the first episode?",
        month: "Month",
        year: "Year",
        personal_experience: "Personal experience"
    },
    drinks: {
        header: "Drinks",
        base_alcohol: "Base alcohol",
        ingredients: "Ingredients",
        type: "Type",
        rating: "Rating"
    },
    recipes: {
        header: "Recipes",
        process: "Process",
        ingredients: "Ingredients",
        duration: "Duration",
        type: "Type"
    },
    elements: {
        select_file: "Select file...",
        image: "Image",
        add: "Add",
        upload: "Upload",
        url: "URL",
        select: "Select",
        selected: "File selected"
    },
    source: "Source"
};

export default en;