 
import { sCAuth } from "schmucklicloud_auth";
import { sCStorage } from "schmucklicloud_storage";

//Note: Also change these value in public/oauth_complete.html since it is a static website
const APP_ID = "64b5be3319b008995e37f9c0f70f281f783e2f9f";
const APP_SECRET = "8fa9a6fe23d62dafb25d27221da67b49003e0f9d0170ec51c933f3b58c7abc03";

var auth = new sCAuth(APP_ID, APP_SECRET);
var storage = new sCStorage(APP_ID, APP_SECRET);

storage.setBucket(22);

var session_token = window.localStorage.getItem("session_token");

storage.setAuthToken(session_token);

export { auth, storage };