<template>
  <div>
    <v-layout row style="margin: 0">
      <v-flex sm3>
        <EList
          :container_name="container_name"
          :header_attribute="header_attribute"
          :description_attribute="description_attribute"
          :image_attribute="image_attribute"
          @itemSelected="showDetailPage"
          @itemAdd="showAddPage"
          :refresh_counter="refresh_counter"
        ></EList>
      </v-flex>
      <v-flex sm9 v-show="!$vuetify.breakpoint.xs">
        <v-card
          style="margin: 20px"
          class="animate__animated animate__fadeInUp"
          v-show="selected_id !== 0"
        >
          <v-card-text>
            <EDetailView
              :item_id="selected_id"
              :container_name="container_name"
              :header_attribute="header_attribute"
              :detail_view_content="detail_view_content"
              :image_attribute="image_attribute"
              :refresh_counter="refresh_counter"
            ></EDetailView>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="showEditPage" text><i class="fad fa-pencil" style="margin-right:5px;"></i>{{ $t("general.edit") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-bottom-sheet
      hide-overlay
      eager
      scrollable
      v-model="item_open"
      v-show="$vuetify.breakpoint.xs"
    >
      <v-sheet style="padding: 3px 3px; height: 100%">
        <v-layout row style="margin: 2px 0 10px 0">
          <v-flex></v-flex>
          <v-flex style="text-align: right">
            <v-btn @click="showEditPage" text><i class="fad fa-pencil" style="margin-right:5px;"></i>{{ $t("general.edit") }}</v-btn>
            <v-btn @click="item_open = false" text>{{
              $t("general.close")
            }}</v-btn>
          </v-flex>
        </v-layout>
        <EDetailView
          :item_id="selected_id"
          :container_name="container_name"
          :header_attribute="header_attribute"
          :detail_view_content="detail_view_content"
          :image_attribute="image_attribute"
          :refresh_counter="refresh_counter"
        ></EDetailView>
      </v-sheet>
    </v-bottom-sheet>
    <EAddDialog
      :open="dialog_add_open"
      :container_name="container_name"
      :elements="management_view_content"
      :suggestion_name="dialog_add_name"
      :suggestion_attribute="header_attribute"
      @closed="dialog_add_open = false; refresh_counter++;"
    ></EAddDialog>
    <EEditDialog
      :open="dialog_edit_open"
      :id="dialog_edit_id"
      :container_name="container_name"
      :elements="management_view_content"
      @closed="dialog_edit_open = false; refresh_counter++;"
      @deleted="dialog_edit_open = false; this.selected_id = 0; refresh_counter++;"
    ></EEditDialog>
  </div>
</template>

<script>
export default {
  name: "EMasterDetailView",
  props: {
    container_name: {
      // The container of the schmuckliCloud service, which should display the data.
      type: String,
      required: true,
    },
    header_attribute: {
      // The main attribute, which should be on top
      type: String,
      default: "name",
    },
    description_attribute: {
      type: String,
      required: false,
    },
    image_attribute: {
      type: String,
      required: false,
    },
    detail_view_content: {
      type: Array,
      required: true,
    },
    management_view_content: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected_id: 0,
      item_open: false,

      refresh_counter: 0,

      dialog_add_open: false,
      dialog_add_name: "",

      dialog_edit_open: false,
      dialog_edit_id: 0
    };
  },
  methods: {
    showDetailPage(id) {
      this.selected_id = id;

      if (this.$vuetify.breakpoint.xs) {
        this.item_open = true;
      }
    },
    showAddPage(name) {
      this.dialog_add_open = true;
      this.dialog_add_name = name;
    },
    showEditPage() {
      this.dialog_edit_open = true;
      this.dialog_edit_id = this.selected_id;
    },
  },
};
</script>

<style>
.v-bottom-sheet {
  overflow: scroll !important;
}
</style>