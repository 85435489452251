<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped :permanent="$vuetify.breakpoint.lg">
      <v-list dense shaped>
        <v-list-item-group v-model="nav_model" mandatory>
          <v-subheader>{{ $t("general.text") }}</v-subheader>
          <v-list-item @click="openOverview" link>
            <v-list-item-action>
              <i class="fad fa-file-alt"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("general.entries") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>{{ $t("general.location") }}</v-subheader>
          <v-list-item @click="openTimeline" link>
            <v-list-item-action>
              <i class="fad fa-map-marked-alt"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("timeline.header") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openTracks" link>
            <v-list-item-action>
              <i class="fad fa-map-marker-alt"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("tracks.header") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>{{ $t('general.activities') }}</v-subheader>
          <v-list-item @click="openFitnessStats" link>
            <v-list-item-action>
              <i class="fad fa-chart-bar"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("fitness.stats") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openFitness" link>
            <v-list-item-action>
              <i class="fad fa-shoe-prints"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("fitness.header") }} Daily</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>{{ $t("general.media") }}</v-subheader>
          <v-list-item @click="openMovies" link>
            <v-list-item-action>
              <i class="fad fa-film"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("movies.header") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openTVShows" link>
            <v-list-item-action>
              <i class="fad fa-tv"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("tvshows.header") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>{{ $t('general.other') }}</v-subheader>
          <v-list-item @click="openDrinks" link>
            <v-list-item-action>
              <i class="fad fa-glass-martini"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("drinks.header") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openRecipes" link>
            <v-list-item-action>
              <i class="fad fa-book"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("recipes.header") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>{{ $t("general.general") }}</v-subheader>
          <v-list-item @click="openSettings" link>
            <v-list-item-action>
               <i class="fad fa-cog"></i>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t("general.settings") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider></v-divider>
        <v-subheader>&copy; {{ "2020 - " + (new Date()).getFullYear() }} by schmuckli.dev</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="isDark"></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("general.dark_mode") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left style="background-color:#FFB024;">
      <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.lg" @click.stop="drawer = !drawer" />
      <img
        src="/apple-icon.png"
        width="30"
        height="30"
        alt="Tasks"
        style="margin-right:10px;"
      />
      <v-toolbar-title>{{ $t('general.app_name') }}</v-toolbar-title>
    </v-app-bar>

    <v-content>
      <v-container class fluid>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </v-container>
    </v-content>

    <!--<v-footer app>
      <span>&copy; 2019</span>
    </v-footer>-->
    <!-- Notification -->
    <v-snackbar v-model="notification">
      {{ notification_text }}
      <v-btn depressed @click="notification = ''">{{ $t("general.close") }}</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import {
  Notifications,
  NotificationsReceiver
} from "./notification";
import { auth, storage } from "./schmucklicloud";

export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    isDark:
      localStorage.getItem("isDark") === undefined
        ? false
        : localStorage.getItem("isDark") === "true"
        ? true
        : false,
    nav_model: 0
  }),
  methods: {
    openOverview() {
      this.$router.push("/");
    },
    openSettings() {
      this.$router.push("/settings");
    },
    openTimeline() {
      this.$router.push("/timeline");
    },
    openFitness() {
      this.$router.push("/fitness");
    },
    openFitnessStats() {
      this.$router.push("/fitness_stats");
    },
    openTracks() {
      this.$router.push("/tracks");
    },
    openMovies() {
      this.$router.push("/movies");
    },
    openTVShows() {
      this.$router.push("/tvshows");
    },
    openDrinks() {
      this.$router.push("/drinks");
    },
    openRecipes() {
      this.$router.push("/recipes")
    },
    updateNavSelection(path) {
      if (!path) {
        path = this.$route.path;
      }
      
      switch(path.split("/")[1]) {
        case "timeline":
          this.nav_model = 1;
          break;
        case "tracks":
        case "track":
          this.nav_model = 2;
          break;
        case "fitness_stats":
          this.nav_model = 3;
          break;
        case "fitness":
          this.nav_model = 4;
          break;
        case "movies":
        case "movie":
          this.nav_model = 5;
          break;
        case "tvshows":
        case "tvshow":
          this.nav_model = 6;
          break;
        case "drinks":
          this.nav_model = 7;
          break;
        case "recipes":
          this.nav_model = 8;
          break;
        case "settings":
          this.nav_model = 9;
          break;
        default:
          this.nav_model = 0;
          break;
      }
    }
  },
  created() {
    this.$vuetify.theme.dark = this.isDark;
  },
  async mounted() {
    var session_token = localStorage.getItem("session_token");
    var response = await auth.checkSession(session_token);
    if (response.status_code !== 200) {
      this.$router.replace("/login");
    } else {
      storage.setAuthToken(session_token);
      setTimeout(this.setupMessaging, 3000);
    }

    this.updateNavSelection();
  },
  watch: {
    isDark() {
      localStorage.setItem("isDark", this.isDark);
      this.$vuetify.theme.dark = this.isDark;
    },
    $route($to) {
      this.updateNavSelection($to.path);
    }
  },
  computed: {
    /* Notification */
    notification: {
      get() {
        return NotificationsReceiver.notification !== "";
      },
      set() {
        Notifications.show("");
      }
    },
    notification_text() {
      return NotificationsReceiver.notification;
    }
  }
};
</script>

<style>
@import url("./assets/css/layout.css");
</style>