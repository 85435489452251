const de = {
    general: {
        app_name: "Tracky",
        entries: "Einträge",
        media: "Medien",
        other: "Andere",
        dark_mode: "Dunkle Ansicht",
        settings: "Einstellungen",
        general: "Generell",
        edit: "Bearbeiten",
        save: "Speichern",
        delete: "Löschen",
        close: "Schliessen",
        search: "Suche",
        new: "Neu",
        location: "Lokalitäten",
        activities: "Aktivitäten",
        no_date_defined: "Kein Datum definiert",
        day_of_week: {
            monday: "Montag",
            tuesday: "Dienstag",
            wednesday: "Mittwoch",
            thursday: "Donnerstag",
            friday: "Freitag",
            saturday: "Samstag",
            sunday: "Sonntag"
        },
        month: {
            january: "Januar",
            february: "Februar",
            march: "März",
            april: "April",
            may: "Mai",
            june: "Juni",
            july: "Juli",
            august: "August",
            september: "September",
            october: "Oktober",
            november: "November",
            december: "Dezember"
        }
    },
    today: {
        notification: {
            entry_saved: "Der Eintrag wurde gespeichert."
        },
        header: "Täglich",
        add: "Hinzufügen",
        title: "Titel",
        text: "Text",
        save: "Speichern",
        mood: "Stimmung",
        entry: "Eintrag",
        edit: "Bearbeiten",
        select_date: "Datum auswählen",
        total_locations_at_this_point: "Anzahl Punkte"
    },
    mood_card: {
        notification: {
            mood_saved: "Die Stimmung wurde gespeichert."
        },
        mood: "Stimmung"
    },
    location_card: {
        header: "Standorte",
        open_location_points: "Öffne Standortpunkte"
    },
    sessioncard: {
        delete: "Löschen",
        device_name: "Gerätename",
        device_os: "Betriebssystem",
        started: "Gestartet"
    },
    settings: {
        header: "Einstellungen",
        language: "Sprache",
        change_password: "Passwort ändern",
        current_password: "Aktuelles Passwort",
        new_password: "Neues Passwort",
        repeat_new_password: "Neues Passwort wiederholen",
        change: "ändern",
        profile: "Profil",
        sign_out: "Ausloggen",
        devices: "Geräte",
        devices_description: "Hier findest du alle Geräte, welche mit deinem Konto verknüpft sind.",
        export_my_data: "Exportiere meine Daten"
    },
    register: {
        email: "E-Mail",
        password: "Passwort",
        repeat_password: "Passwort wiederholen",
        register: "Registrieren",
        or_want_to_login: "oder einloggen?"
    },
    login: {
        login: "Login",
        email: "E-Mail",
        password: "Passwort",
        register_now: "Registrieren?",
        forgot_password: "Passwort vergessen?"
    },
    forgot_password: {
        header: "Passwort vergessen?",
        header_description: "Gebe unten deine E-Mail Adresse ein. Danach werden wir dir einen Link schicken, wo du dein Passwort zurücksetzen kannst.",
        email: "E-Mail",
        request_password: "Passwort anfordern",
        or_want_to_login: "Einloggen?"
    },
    reset_password: {
        notification: {
            new_password_has_been_set: "Das neue Passwort wurde gesetzt.",
            passwords_do_not_match: "Die Passwörter stimmen nicht überein."
        },
        header: "Passwort zurücksetzen",
        header_description: "Du kannst hier dein neues Passwort definieren, welches dann ab sofort aktiv ist.",
        new_password: "Neues Passwort",
        repeat_new_password: "Neues Passwort wiederholen",
        reset: "Zurücksetzen"
    },
    activate: {
        notification: {
            user_activated: "Der Benutzer wurde aktiviert."
        }
    },

    timeline: {
        header: "Zeitachse",
        no_data_recorded_for_this_date_yet: "Es wurden noch keine Daten aufgezeichnet für diesen Tag.",
        install_the_companion: "Installiere die Companion App um deine Zeitachse zu sehen.",
        or: "Oder",
        try_import_snapplace: "Probiere einen Import aus SnapPlace",
        you_can_import_this_day_tomorrow: "Du kannst diesen Tag erst morgen importieren.",
        on_the_way: "Unterwegs"
    },

    tracks: {
        header: "Routen",
        distance: "Distanz",
        import: "Import",
        name: "Name",
        elevation: "Höhe",
        meters_above_sea: "Meter über Meer",
        bpm: "BPM",
        heartRate: "Herzfrequenz",
        duration: "Dauer",
        date: "Datum",
        time: "Zeit",
        edit: "Bearbeiten",
        type: "Typ",
        search: "Nach Tracks suchen...",
        notification: {
            track_has_been_imported: "Der Track wurde erfolgreich importiert.",
            file_not_supported: "Die Datei wird nicht unterstützt.",
            track_has_been_saved: "Der Track wurde gespeichert.",
            track_has_been_deleted: "Der Track wurde gelöscht."
        },
        types: {
            driving: "Auto fahren",
            walking: "Gehen",
            running: "Joggen",
            hiking: "Wandern",
            biking: "Velo fahren",
            sailing: "Segeln",
            boating: "Mit Boot",
            skiing: "Ski fahren",
            snowboarding: "Snowboarden",
            sledding: "Schlitteln"
        },
        heartRateAvg: "Durchschnitt Herzfrequenz",
        uphill: "Höhe hoch",
        downhill: "Höhe runter"
    },

    fitness: {
        header: "Fitness",
        steps: "Schritte",
        calories: "Kalorien",
        floors: "Etagen",
        distance: "Distanz",
        resting_heart_rate: "Durchschnittliche Herzfrequenz",
        sleep_effective: "Effektive Schlafdauer (ohne Wachphasen)",
        sleep_total: "Totale Schlafdauer",
        import_data: "Import von Services",

        no_data_here: "Hier sind keine Daten für dieses Datum.",

        all_time: "Gesamt",
        all_time_steps: "Schritte (Gesamt)",
        all_time_average_steps: "Schritte pro Tag (Durchschnitt)",
        all_time_distance: "Distanz (Gesamt)"
    },

    movies: {
        notification: {
            saved_personal_experience: "Die persönliche Erfahrung wurde gespeichert.",
            personal_experience_deleted: "Die persönliche Erfahrung wurde gelöscht."
        },
        do_you_really_want_to_delete: "Möchtest du diese Erfahrung wirklich löschen?",
        header: "Filme",
        back: "zurück",
        runtime: "Laufzeit",
        minutes: "Minuten",
        search_for_movies: "Nach Filmen suchen...",
        have_you_watched_this_movie: "Hast du diesen Film gesehen?",
        watched_where: "Wo gesehen?",
        watched_where_cinema: "Kino",
        watched_where_streamed: "Stream/VOD (Netflix, Disney+, ...)",
        watched_where_tv: "TV (Free-TV Premiere, ...)",
        watched_where_both: "Im Kino und im Stream / TV",
        month: "Monat",
        year: "Jahr",
        rating: "Bewertung",
        watched_when: "Wann geschaut?",
        personal_experience: "Persönliche Erfahrung",
        library: "Bibliothek"
    },

    tvshows: {
        notification: {
            saved_personal_experience: "Die persönliche Erfahrung wurde gespeichert.",
            personal_experience_deleted: "Die persönliche Erfahrung wurde gelöscht."
        },
        do_you_really_want_to_delete: "Möchtest du diese Erfahrung wirklich löschen?",
        header: "Serien",
        library: "Bibliothek",
        search_for_tvshows: "Nach Serien suchen...",
        seasons: "Staffeln",
        back: "Zurück",
        have_you_watched_this_show: "Hast du diese Serie gesehen?",
        rating: "Bewertung",
        watched_when: "Wann hast du die erste Episode geschaut?",
        month: "Monat",
        year: "Jahr",
        personal_experience: "Persönliche Erfahrung"
    },

    drinks: {
        header: "Drinks",
        base_alcohol: "Hauptalkohol",
        ingredients: "Zutaten",
        type: "Art",
        rating: "Bewertung"
    },
    recipes: {
        header: "Rezepte",
        type: "Art",
        ingredients: "Zutaten",
        process: "Ablauf",
        duration: "Dauer"
    },
    elements: {
        add: "Hinzufügen",
        select_file: "Datei auswählen...",
        image: "Bild",
        upload: "Hochladen",
        url: "URL",
        select: "Auswählen",
        selected: "Datei ausgewählt"
    },
    source: "Quelle"
};

export default de;