import Vue from 'vue';
import VueRouter from 'vue-router';

import { auth } from "./schmucklicloud";
import { Notifications } from "./notification";

Vue.use(VueRouter);

const router = new VueRouter({
    //mode: "history",
    routes: [
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/',
            name: 'Daily',
            component: () => import('./views/Daily'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/timeline",
            name: "Timeline",
            component: () => import('./views/Timeline'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/timeline/:date",
            name: "TimelineWithDate",
            component: () => import('./views/Timeline'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/tracks",
            name: "Tracks",
            component: () => import('./views/Tracks'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/track/:id",
            name: "TrackDetail",
            component: () => import('./views/TrackDetail'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/movies',
            name: 'Movies',
            component: () => import('./views/Movies'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/movie/:id',
            name: 'MovieDetail',
            component: () => import('./views/MovieDetail'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tvshows',
            name: 'TVShows',
            component: () => import('./views/TVShows'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tvshow/:id',
            name: 'TVShowDetail',
            component: () => import('./views/TVShowDetail'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/fitness',
            name: 'Fitness',
            component: () => import('./views/Fitness'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/fitness_stats',
            name: 'Fitness Stats',
            component: () => import('./views/FitnessStats'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/drinks',
            name: 'Drinks',
            component: () => import('./views/elements/Drinks'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/recipes',
            name: 'Recipes',
            component: () => import('./views/elements/Recipes'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/login",
            name: "Login",
            component: () => import('./views/Login'),
            meta: {
                requiresAuth: false,
                redirectIfAuth: true
            }
        },
        {
            path: "/register",
            name: "Register",
            component: () => import('./views/Register'),
            meta: {
                requiresAuth: false,
                redirectIfAuth: true
            }
        },
        {
            path: "/activate",
            name: "Activate",
            component: () => import('./views/Activate'),
            meta: {
                requiresAuth: false,
                redirectIfAuth: true
            }
        },
        {
            path: "/settings",
            name: "Settings",
            component: () => import('./views/Settings'),
            meta: {
                requiresAuth: true
            }
        },
    ]
});

router.beforeEach(async (to, from, next) => {
    var token = localStorage.getItem("session_token");
    var response = await auth.checkSession(token);

    var isLoggedIn = response.isOK;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const redirectIfAuth = to.matched.some(record => record.meta.redirectIfAuth);
  
    if (requiresAuth && !isLoggedIn) {
      Notifications.show("You are not signed in.");
      next('/login')
    }
    else if (isLoggedIn && redirectIfAuth) next('/dashboard');
    else next();
  });

export default router;