<template>
  <div>
    <v-text-field
      v-model="query"
      autocomplete="off"
      :loading="loading"
      :placeholder="$t('general.search') + '...'"
    ></v-text-field>
    <v-list>
      <v-list-item
        two-line
        v-for="result in query_results"
        :key="result.id"
        @click="
          () => {
            selectItem(result.id);
          }
        "
      >
        <v-list-item-avatar v-if="image_attribute">
          <div
            :style="
              'background-image: url(' +
              result.data[image_attribute] +
              ');background-size:cover;width:40px;height:40px;background-position:center;'
            "
          ></div>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ result.data[header_attribute] }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ result.data[description_attribute] }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="addItem">
        <v-list-item-avatar>
          <i class="fad fa-plus" style="font-size: 20px"></i>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ $t("general.new") }}</v-list-item-title>
          <v-list-item-subtitle>{{ query }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { storage } from "../../schmucklicloud";

export default {
  props: {
    container_name: {
      // The container of the schmuckliCloud service, which should display the data.
      type: String,
      required: true,
    },
    header_attribute: {
      // The main attribute, which should be on top
      type: String,
      default: "name",
    },
    description_attribute: {
      type: String,
      required: false,
    },
    image_attribute: {
      type: String,
      required: false,
    },
    refresh_counter: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      query: "",
      query_results: [],

      loading: false,
    };
  },
  mounted() {
    this.search("");
  },
  methods: {
    async search(query) {
      query = query.trim();
      this.loading = true;
      var response = await storage.get(
        this.container_name,
        [
          {
            column: this.header_attribute,
            operator: "contains_i",
            value: query,
          },
        ],
        [{ column: this.header_attribute, order: "asc" }],
        0,
        10
      );

      if (response.isOK) {
        this.query_results = response.data || [];
      }
      this.loading = false;
    },
    selectItem(id) {
      this.$emit("itemSelected", id);
    },
    addItem() {
      this.$emit("itemAdd", this.query);
    },
  },
  watch: {
    query(new_query) {
      this.search(new_query);
    },
    refresh_counter() {
      this.search(this.query);
    }
  },
};
</script>

<style>
</style>